<ng-container *mpHeaderElement="'left'; let activatedRouteTitle">
  <mp-root-module-nav [label]="activatedRouteTitle" [rootModuleLink]="rootModuleLink" />
</ng-container>

<mp-search-bar
  *mpHeaderElement="'center'"
  class="mpctm-conditions-header__search-bar"
  [searchTerm]="searchTerm"
  [field]="field"
  [fieldOptions]="headerFieldOptions"
  searchFieldPlaceholder="Suche nach"
  (manualSearch)="onManualSearch($event)"
/>
