<ng-container *ngrxLet="conditionsState$ as conditionsState">
  <mpctm-conditions-header
    [searchTerm]="conditionsState.conditionsSearchRequest.searchTerm ?? ''"
    (manualSearch)="onSearch(conditionsState.conditionsSearchRequest, $event)"
  />

  <div class="mp-page mp-page--border mp-page--scrollable">
    <div class="mp-page__main mp-page__main--fixed-layout">
      <mp-aside-filters
        class="mp-page__aside-filters"
        [filtersSummary]="
          conditionsState.conditionsSearchRequest | mpctmConditionsAsideFiltersSummary: conditionsState.conditionsFacets
        "
        (filtersReset)="onFilterReset()"
      >
        @if (conditionsState.conditionsFacets) {
          <mpctm-conditions-filter-area
            [facets]="conditionsState.conditionsFacets"
            [searchFilter]="conditionsState.conditionsSearchRequest"
            (filterChange)="onFilterChange($event)"
          />
        } @else {
          <mp-spinner class="loading-spinner" />
        }
      </mp-aside-filters>

      @if (conditionsState.conditionsLoaded) {
        <div class="mp-page__content mp-page--padding" mpRestoreScrolling itemKey="mpctm-conditions-overview">
          <div class="mp-page__content-inner--centered">
            <div class="conditions-overview-header">
              <mp-list-header
                class="mp-list-header--spaced"
                [headerValue]="conditionsState.conditionsSearchRequest.searchTerm"
                [totalItemsQuantity]="conditionsState.conditionsPaginationMetadata?.totalRowCount ?? 0"
                [showListHeaderSearchLabel]="true"
                headerPlaceholder="Konditionen"
              />
              <mp-dropdown
                class="conditions-overview-header__sort"
                [options]="conditionSortOptions"
                label="Sortierung"
                [selectedValue]="conditionsState.conditionsSearchRequest.sort"
                (selectedValueChange)="onSortChange(conditionsState.conditionsSearchRequest, $event)"
              />
            </div>
            <mpctm-conditions-list [conditions]="conditionsState.conditions" />

            @if (conditionsState.conditionsPaginationMetadata; as pagination) {
              <mp-paginator
                [pagination]="pagination"
                (page)="onPageChange(conditionsState.conditionsSearchRequest, $event)"
              />
            }
          </div>
        </div>
      } @else {
        <mp-spinner class="loading-spinner" />
      }
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <mp-spinner class="loading-spinner" />
</ng-template>
