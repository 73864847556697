import { SelectOption } from '@core/ui';
import { ConditionSort, ConditionSortField } from '@mp/contract-management/conditions/domain';

export const CONDITIONS_SORT_FIELD_OPTIONS: SelectOption<ConditionSort>[] = [
  { label: 'Konditionsnummer (aufsteigend)', value: ConditionSortField.ConditionNumber },
  { label: 'Konditionsnummer (absteigend)', value: `-${ConditionSortField.ConditionNumber}` },
  { label: 'Vertragsnummer (aufsteigend)', value: ConditionSortField.ContractNumber },
  { label: 'Vertragsnummer (absteigend)', value: `-${ConditionSortField.ContractNumber}` },
  { label: 'Gültig von (aufsteigend)', value: ConditionSortField.ValidFrom },
  { label: 'Gültig von (absteigend)', value: `-${ConditionSortField.ValidFrom}` },
  { label: 'Gültig bis (aufsteigend)', value: ConditionSortField.ValidUntil },
  { label: 'Gültig bis (absteigend)', value: `-${ConditionSortField.ValidUntil}` },
  { label: 'Datum Anlage (aufsteigend)', value: ConditionSortField.CreatedAt },
  { label: 'Datum Anlage (absteigend)', value: `-${ConditionSortField.CreatedAt}` },
  { label: 'Letzte Änderung (aufsteigend)', value: ConditionSortField.ModifiedAt },
  { label: 'Letzte Änderung (absteigend)', value: `-${ConditionSortField.ModifiedAt}` },
];
