import { permissions } from '@mpk/shared/domain';

/**
 * Permission for the conditions
 */
enum ConditionsPermissionsEnum {
  None = 0,
  /**
   * Conditions in contract management can be viewed
   */
  ViewConditions = 1,

  /**
   * Conditions in contract management can be viewed and assigned
   */
  ManageConditions = 2 | ViewConditions,
}

export const ConditionsPermissions = permissions(ConditionsPermissionsEnum, {
  featureId: 'Conditions',
});
