import { Params } from '@angular/router';

import {
  ConditionQueryField,
  ConditionSortField,
  ConditionStatusFilter,
  ConditionsSearchRequest,
} from '@mp/contract-management/conditions/domain';
import { LogicalChoiceFilter, getArrayValue } from '@mp/shared/data-access';

export const DEFAULT_SEARCH_REQUEST: ConditionsSearchRequest = {
  page: 1,
  pageSize: 50,
  sort: `-${ConditionSortField.ModifiedAt}`,
  contractApproved: LogicalChoiceFilter.All,
  contractExists: LogicalChoiceFilter.All,
  status: ConditionStatusFilter.All,
  field: ConditionQueryField.All,
};

export function getSearchRequestFromQueryParams(queryParams: Params): ConditionsSearchRequest {
  return {
    page: parseInt(queryParams['page'] || DEFAULT_SEARCH_REQUEST.page),
    pageSize: parseInt(queryParams['pageSize'] || DEFAULT_SEARCH_REQUEST.pageSize),
    sort: queryParams['sort'] || DEFAULT_SEARCH_REQUEST.sort,
    contractApproved: queryParams['contractApproved'] || DEFAULT_SEARCH_REQUEST.contractApproved,
    contractExists: queryParams['contractExists'] || DEFAULT_SEARCH_REQUEST.contractExists,
    status: queryParams['status'] || DEFAULT_SEARCH_REQUEST.status,
    field: queryParams['field'] ?? DEFAULT_SEARCH_REQUEST.field,
    dataSources: getArrayValue(queryParams['dataSources']),
    departments: getArrayValue(queryParams['departments']),
    suppliers: getArrayValue(queryParams['suppliers']),
    searchTerm: queryParams['searchTerm'],
    validFrom: queryParams['validFrom'],
    validUntil: queryParams['validUntil'],
    visibleFrom: queryParams['visibleFrom'],
  };
}
