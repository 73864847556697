import { ConditionQueryField } from '@mp/contract-management/conditions/domain';

export const CONDITIONS_FIELD_OPTIONS: { key: ConditionQueryField; label: string }[] = [
  {
    key: ConditionQueryField.All,
    label: 'Alle',
  },
  {
    key: ConditionQueryField.ConditionNumber,
    label: 'Konditionsnummer',
  },
  {
    key: ConditionQueryField.ConditionName,
    label: 'Konditionsbezeichnung',
  },
  {
    key: ConditionQueryField.ContractNumber,
    label: 'Vertrags-Nr.',
  },
  {
    key: ConditionQueryField.LeadingDepartment,
    label: 'Führender Fachbereich',
  },
  {
    key: ConditionQueryField.CategoryBuyer,
    label: 'Facheinkäufer',
  },
  {
    key: ConditionQueryField.SupplierName,
    label: 'Lieferanten Bez.',
  },
  {
    key: ConditionQueryField.SupplierShortName,
    label: 'Lieferanten Kurz Bez.',
  },
  {
    key: ConditionQueryField.SupplierNumber,
    label: 'Lieferanten Nr.',
  },
  {
    key: ConditionQueryField.UserCreated,
    label: 'Benutzer Anlage Konditionen',
  },
  {
    key: ConditionQueryField.UserModified,
    label: 'Benutzer Bearbeitung Konditionen',
  },
];
