@if (facets.suppliers.buckets.length) {
  <mp-multi-select-facet
    filterName="Lieferant"
    icon="local_shipping"
    [facet]="facets.suppliers"
    [maxVisibleItems]="3"
    searchable
    searchFieldPlaceholder="Lieferanten durchsuchen"
    showSelectionInfo
    selectionKey="conditionsSuppliers"
    (changed)="handleFacetChange({ suppliers: $event })"
  >
    <ng-template let-bucket>
      <mat-icon class="material-icons-outlined" [mpIcon]="supplierFacetIconSelector(bucket)" />

      <div class="facet--bucket-label truncate" [title]="bucket.label">
        {{ bucket.label }}
      </div>
    </ng-template>
  </mp-multi-select-facet>
}

@if (facets.departments.buckets.length) {
  <mp-multi-select-facet
    filterName="Führender Fachbereich"
    icon="category"
    [facet]="facets.departments"
    [maxVisibleItems]="3"
    searchable
    searchFieldPlaceholder="Fachbereichen durchsuchen"
    showSelectionInfo
    selectionKey="conditionsDepartments"
    (changed)="handleFacetChange({ departments: $event })"
  />
}

<mp-date-range-facet
  title="Gültigkeitzeitraum"
  icon="event"
  [dateFrom]="searchFilter.validFrom"
  [dateTo]="searchFilter.validUntil"
  selectionKey="conditionsValidDateRange"
  (dateRangeChange)="handleValidDateRangeChange($event)"
/>

<mp-date-range-facet
  title="Sichtbarkeit"
  icon="event"
  [dateFrom]="searchFilter.visibleFrom"
  [showDateTo]="false"
  selectionKey="conditionsVisibleFrom"
  (dateRangeChange)="handleVisibleFromDateChange($event.dateFrom)"
/>

@if (facets.statuses.buckets.length) {
  <mp-single-select-facet
    filterName="Konditionsstatus"
    icon="history_edu"
    [facet]="facets.statuses"
    selectionKey="conditionsContractStatus"
    (changed)="handleStatusChange($event)"
  />
}

@if (facets.dataSources.buckets.length) {
  <mp-multi-select-facet
    filterName="Datenquellen"
    icon="@mdi:database-outline"
    [facet]="facets.dataSources"
    [maxVisibleItems]="3"
    searchable
    searchFieldPlaceholder="Datenquellen durchsuchen"
    showSelectionInfo
    selectionKey="conditionsDataSources"
    (changed)="handleFacetChange({ dataSources: $event })"
  />
}

<mp-logical-choice-dropdown
  class="logical-choice-dropdown"
  title="Vertrag freigegeben"
  icon="check_circle_outline"
  [selectedValue]="searchFilter.contractApproved ?? LogicalChoiceFilter.All"
  (selectionChange)="handleFacetChange({ contractApproved: $event })"
/>

<mp-logical-choice-dropdown
  class="logical-choice-dropdown"
  title="Vertrag vorhanden"
  icon="inventory"
  [selectedValue]="searchFilter.contractExists ?? LogicalChoiceFilter.All"
  (selectionChange)="handleFacetChange({ contractExists: $event })"
/>
