import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { HeaderElementDirective } from '@core/shared/util';
import { FieldOption, RootModuleNavComponent, SearchBarComponent, SearchBarSearchEvent } from '@core/ui';
import { ConditionQueryField } from '@mp/contract-management/conditions/domain';
import { PageLink } from '@mp/shared/data-access';

import { ConditionsSearchBarSearchEvent } from '../../models';

import { CONDITIONS_FIELD_OPTIONS } from './conditions-header-field-options';

@Component({
  selector: 'mpctm-conditions-header',
  standalone: true,
  templateUrl: './conditions-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderElementDirective, RootModuleNavComponent, SearchBarComponent],
})
export class ConditionsHeaderComponent {
  @HostBinding() readonly class = 'mpctm-conditions-header';

  @Input() rootModuleLink: PageLink = ['../'];

  @Input() searchTerm = '';

  @Input() field: ConditionQueryField = ConditionQueryField.All;

  @Output() readonly manualSearch: EventEmitter<ConditionsSearchBarSearchEvent> =
    new EventEmitter<ConditionsSearchBarSearchEvent>();

  readonly headerFieldOptions: FieldOption[] = CONDITIONS_FIELD_OPTIONS;

  onManualSearch(searchEvent: SearchBarSearchEvent): void {
    this.manualSearch.emit(searchEvent as ConditionsSearchBarSearchEvent);
  }
}
