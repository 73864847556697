import { Pipe, PipeTransform } from '@angular/core';

import { FilterInfo } from '@core/ui';
import {
  ConditionStatusFilter,
  ConditionsFacets,
  ConditionsSearchRequest,
} from '@mp/contract-management/conditions/domain';
import { LogicalChoiceFilter } from '@mp/shared/data-access';

@Pipe({
  name: 'mpctmConditionsAsideFiltersSummary',
  standalone: true,
})
export class ConditionsAsideFiltersSummaryPipe implements PipeTransform {
  transform(searchRequest: ConditionsSearchRequest, conditionsFacets: ConditionsFacets | undefined): FilterInfo[] {
    if (!conditionsFacets) {
      return [];
    }

    return this.getAsideFiltersSummary(searchRequest, conditionsFacets);
  }

  private getAsideFiltersSummary(
    searchRequest: ConditionsSearchRequest,
    conditionsFacets: ConditionsFacets,
  ): FilterInfo[] {
    const {
      suppliers,
      departments,
      validFrom,
      validUntil,
      visibleFrom,
      status,
      dataSources,
      contractApproved,
      contractExists,
    } = searchRequest;

    return [
      {
        icon: 'local_shipping',
        title: 'Lieferant',
        isActive: !!suppliers?.length,
        hidden: conditionsFacets.suppliers.buckets.length === 0,
      },
      {
        icon: 'category',
        title: 'Führender Fachbereich',
        isActive: !!departments?.length,
        hidden: conditionsFacets.departments.buckets.length === 0,
      },
      {
        icon: 'event',
        title: 'Gültigkeitzeitraum',
        isActive: !!validFrom || !!validUntil,
      },
      {
        icon: 'event',
        title: 'Sichtbarkeit',
        isActive: !!visibleFrom,
      },
      {
        icon: 'history_edu',
        title: 'Konditionsstatus',
        isActive: status !== ConditionStatusFilter.All,
        hidden: conditionsFacets.statuses.buckets.length === 0,
      },
      {
        icon: '@mdi:database-outline',
        title: 'Datenquellen',
        isActive: !!dataSources?.length,
        hidden: conditionsFacets.dataSources.buckets.length === 0,
      },
      {
        icon: 'check_circle_outline',
        title: 'Vertrag freigegeben',
        isActive: contractApproved !== LogicalChoiceFilter.All,
      },
      {
        icon: 'inventory',
        title: 'Vertrag vorhanden',
        isActive: contractExists !== LogicalChoiceFilter.All,
      },
    ];
  }
}
