import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective } from '@core/shared/util';
import { LogicalChoiceDropdownComponent } from '@core/ui';
import {
  ConditionStatusFilter,
  ConditionsFacets,
  ConditionsSearchRequest,
} from '@mp/contract-management/conditions/domain';
import { LogicalChoiceFilter } from '@mp/shared/data-access';
import { DateRange, FacetsModule } from '@mp/shared/facets/ui';
import { supplierFacetIconSelector } from '@mp/shared/facets/util';

@Component({
  selector: 'mpctm-conditions-filter-area',
  standalone: true,
  templateUrl: './conditions-filter-area.component.html',
  styleUrls: ['./conditions-filter-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, FacetsModule, IconDirective, LogicalChoiceDropdownComponent],
})
export class ConditionsFilterAreaComponent {
  @Input({ required: true }) facets!: ConditionsFacets;

  @Input({ required: true }) searchFilter!: ConditionsSearchRequest;

  @Output() readonly filterChange: EventEmitter<ConditionsSearchRequest> = new EventEmitter<ConditionsSearchRequest>();

  readonly supplierFacetIconSelector = supplierFacetIconSelector;

  readonly LogicalChoiceFilter = LogicalChoiceFilter;

  handleFacetChange(change: Partial<ConditionsSearchRequest>): void {
    this.searchFilter = { ...this.searchFilter, ...change };
    this.filterChange.emit(this.searchFilter);
  }

  handleStatusChange(selectedStatus: string): void {
    this.handleFacetChange({ status: selectedStatus as ConditionStatusFilter });
  }

  handleValidDateRangeChange({ dateFrom, dateTo }: DateRange): void {
    this.handleFacetChange({ validFrom: dateFrom, validUntil: dateTo });
  }

  handleVisibleFromDateChange(visibleFrom: string | undefined): void {
    this.handleFacetChange({ visibleFrom });
  }
}
